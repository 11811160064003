/* You can add global styles to this file, and also import other style files */

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow-y: hidden;
  height: 100%;
}

#main-wrapper .left-sidebar[data-sidebarbg="skin5"] ul {
  overflow-y: auto;
  max-height: calc(100vh - 64px);
}

// scrollbar
/* Para Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: rgb(61, 61, 61) transparent;
}

/* Para Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(61, 61, 61);
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: black;
}

@mixin center-content() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin center-but-space-content() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
*/

app-tiles {
  height: 100%;
  display: block !important;
}

.drop-scroll {
  overflow: hidden !important;
}

.red-circle-altas {
  background-color: red !important;
  border-radius: 100%;
}

.blue-circle-altas {
  background-color: rgb(33, 126, 247) !important;
  border-radius: 100%;
}

.selected-circle-altas {
  background-color: rgb(202, 202, 202) !important;
  border-radius: 100%;
}

// popover body
.cell-popover-no-title {
  .popover-header {
    display: none;
  }
  .popover-body {
    padding: 0;
  }

  .popover-status-row {
    padding: 0.5em 0.8em;
    font-size: 1.2em;

    &:hover:not(.popover-status-row-disabled) {
      background-color: rgba(245, 245, 245, 0.548);
      cursor: pointer;
    }
    .fa-check {
      color: green;
    }

    .fa-times {
      color: red;
    }
  }
}
